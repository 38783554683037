<template>
    <div ref="ocv-line-container" class="ocv-line-container">
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'OCVLine',
    methods: {
        getOption() {
            const option = {
                legend: {
                    right: 10,
                    top: 10,
                    data: ['OCV'],
                    textStyle: {
                        color: '#01AAF2',
                        fontSize: 16
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    top: 40,
                    bottom: 40,
                    left: 60
                },
                xAxis: {
                    type: 'category',
                    data: [
                        0,
                        5,
                        10,
                        15,
                        20,
                        25,
                        30,
                        35,
                        40,
                        45,
                        50,
                        55,
                        60,
                        65,
                        70,
                        75,
                        80,
                        85,
                        90,
                        95,
                        100
                    ],
                    axisLabel: {
                        color: '#21abfa',
                        fontSize: 12,
                        fontWeight: 700
                    },  
                    axisLine: {
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    boundaryGap: false,
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#21abfa',
                        fontSize: 12,
                        fontWeight: 700,
                    }, 
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#094B85'],
                            opacity: 0.3
                        }
                    }
                },
                series: [
                    {
                        name: 'OCV',
                        data: [
                            2715,
                            3193,
                            3200,
                            3217,
                            3256,
                            3270,
                            3284,
                            3287,
                            3287,
                            3286,
                            3289,
                            3296,
                            3312,
                            3328,
                            3328,
                            3327,
                            3328,
                            3329,
                            3330,
                            3331,
                            3343,
                        ],
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            color: 'rgba(45, 146, 255, 1)',
                            width: 3
                        }
                    }
                ]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['ocv-line-container'];
        const myChart = echarts.init(chartDom);
        myChart.setOption(this.getOption());
    }
}
</script>

<style lang="less">
    .ocv-line-container {
        width: 100%;
        height: 100%;
    }
</style>