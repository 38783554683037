<template>
    <div ref="sop-line-container" class="sop-line-container">
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'SOPLine',
    methods: {
        getOption() {
            const option = {
                legend: {
                    right: 10,
                    top: 10,
                    data: ['SOP'],
                    textStyle: {
                        color: '#01AAF2',
                        fontSize: 16
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    top: 40,
                    bottom: 40,
                    left: 60
                },
                xAxis: {
                    type: 'category',
                    data: [
                        0,
                        5,
                        10,
                        15,
                        20,
                        25,
                        30,
                        35,
                        40,
                        45,
                        50,
                        55,
                        60,
                        65,
                        70,
                        75,
                        80,
                        85,
                        90,
                        95,
                        100
                    ],
                    axisLabel: {
                        color: '#21abfa',
                        fontSize: 12,
                        fontWeight: 700
                    },  
                    axisLine: {
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    boundaryGap: false,
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#21abfa',
                        fontSize: 12,
                        fontWeight: 700,
                    }, 
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#094B85'],
                            opacity: 0.3
                        }
                    }
                },
                series: [
                    {
                        name: 'SOP',
                        data: [
                            0,
                            47,
                            99,
                            109,
                            119,
                            129,
                            139,
                            149,
                            159,
                            169,
                            179,
                            189,
                            192,
                            195,
                            198,
                            201,
                            204,
                            207,
                            210,
                            213,
                            216,
                        ],
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            color: 'rgba(45, 146, 255, 1)',
                            width: 3
                        },
                        areaStyle: {
                            color: 'rgba(104, 197, 244, 1)'
                        }
                    }
                ]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['sop-line-container'];
        const myChart = echarts.init(chartDom);
        myChart.setOption(this.getOption());
    }
}
</script>

<style lang="less">
    .sop-line-container {
        width: 100%;
        height: 100%;
    }
</style>