<template>
    <div class="battery-cell-info-container">
        <div class="left-icon-container">
            <img :src="require('../assets/cell-icon.png')"/>
        </div>
        <div class="right-cell-info">
            <div class="cell-info-item" v-for="(item, index) in data" :key="index">
                <span class="name">{{ item.name }}：</span>
                <span class="value">{{ item.value }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BatteryCellInfo',
    props: {
        data: {
            type: Array,
            default: () => [
                { name: '型号', value: '6060' },
                { name: '尺寸', value: '250mmX180mmX26mm' },
                { name: '电芯生产日期', value: '2020-05-23' },
                { name: '标称容量', value: '60Ah' },
                { name: 'PackSN号', value: 'PK504B1010004365' },
                { name: '标称电压', value: '3320mV' },
                { name: '质保条件', value: '3年或4000kwh' },
                { name: 'Pack生产日期', value: '2020-06-01' },
            ]
        }
    }
}
</script>

<style lang="less">
    .battery-cell-info-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0 20px;
        display: flex;
        .left-icon-container {
            width: 120px;
            height: 160px;
            background: rgba(32, 255, 250, .05);
            img {
                height: 100%;
                width: 100%;
            }
        }
        .right-cell-info {
            box-sizing: border-box;
            width: calc(100% - 120px);
            height: 100%;
            padding-left: 30px;
            padding-top: 30px;
            .cell-info-item {
                display: inline-block;
                height: 20px;
                width: 50%;
                line-height: 20px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #E3F0FF
            }
        }
    }
</style>