<template>
    <div ref="attenuation-container" class="attenuation-container">
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'AttenuationLine',
    methods: {
        getOption() {
            const option = {
                legend: {
                    right: 10,
                    top: 10,
                    data: [{
                        name: '总衰减',
                        lineStyle: {
                            color: 'rgba(251, 216, 138, 1)'
                        }
                    }, {
                        name: 'LLI',
                        lineStyle: {
                            color: 'rgba(245, 132, 188, 1)'
                        }
                    }, {
                        name: 'LAM', 
                        lineStyle: {
                            color: 'rgba(104, 197, 244, 1)'
                        }
                    }, {
                        name: 'SEI',
                        lineStyle: {
                            color: 'rgba(63, 227, 237, 1)'
                        }
                    }],
                    textStyle: {
                        color: '#01AAF2',
                        fontSize: 12
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    top: 40,
                    bottom: 40,
                    left: 60
                },
                xAxis: {
                    type: 'category',
                    data: [
                        '2020-05',
                        '2020-06',
                        '2020-07',
                        '2020-08',
                        '2020-09',
                        '2020-10',
                        '2020-11',
                        '2020-12',
                        '2021-01',
                        '2021-02',
                        '2021-03',
                        '2021-04',
                        '2021-05',
                        '2021-06',
                        '2021-07'
                    ],
                    axisLabel: {
                        color: '#21abfa',
                        fontSize: 12,
                        fontWeight: 700
                    },  
                    axisLine: {
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    boundaryGap: false,
                },
                yAxis: {
                    type: 'value',
                    inverse: true,
                    axisLabel: {
                        color: '#21abfa',
                        fontSize: 12,
                        fontWeight: 700,
                    }, 
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#094B85'],
                            opacity: 0.3
                        }
                    }
                },
                series: [
                    {
                        name: '总衰减',
                        data: [
                            0,
                            0,
                            0,
                            0.1,
                            0.2,
                            0.3,
                            0.4,
                            0.5,
                            0.5,
                            0.6,
                            0.7,
                            0.8,
                            0.9,
                            1,
                            1.1
                        ],
                        type: 'line',
                        showSymbol: false,
                        itemStyle: {
                            color: 'rgba(251, 216, 138, 1)',
                        },
                        areaStyle: {
                            color: 'rgba(251, 216, 138, 1)'
                        }
                    },
                    {
                        name: 'LLI',
                        data: [
                            0,
                            0,
                            0,
                            0.08,
                            0.14,
                            0.2,
                            0.28,
                            0.38,
                            0.38,
                            0.45,
                            0.55,
                            0.64,
                            0.7,
                            0.8,
                            0.84
                        ],
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        itemStyle: {
                            color: 'rgba(245, 132, 188, 1)',
                        },
                        areaStyle: {
                            color: 'rgba(245, 132, 188, 1)'
                        }
                    },
                    {
                        name: 'LAM',
                        data: [
                            0,
                            0,
                            0,
                            0.02,
                            0.04,
                            0.06,
                            0.08,
                            0.08,
                            0.08,
                            0.1,
                            0.1,
                            0.1,
                            0.13,
                            0.13,
                            0.16,
                        ],
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        itemStyle: {
                            color: 'rgba(104, 197, 244, 1)',
                        },
                        areaStyle: {
                            color: 'rgba(104, 197, 244, 1)'
                        }
                    },
                    {
                        name: 'SEI',
                        data: [
                            0,
                            0,
                            0,
                            0,
                            0.02,
                            0.04,
                            0.04,
                            0.04,
                            0.04,
                            0.05,
                            0.05,
                            0.06,
                            0.07,
                            0.07,
                            0.1,
                        ],
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        itemStyle: {
                            color: 'rgba(63, 227, 237, 1)',
                        },
                        areaStyle: {
                            color: 'rgba(63, 227, 237, 1)'
                        }
                    },
                ]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['attenuation-container'];
        const myChart = echarts.init(chartDom);
        myChart.setOption(this.getOption());
    }
}
</script>

<style lang="less">
    .attenuation-container {
        width: 100%;
        height: 100%;
    }
</style>