<template>
    <div 
        ref="data-center-cell-container"
        class="data-center-cell-container"
        :style="pageStyle"
    >
        <div v-if="loading" class="loading-container">
            <div class="loading-container">
                <div id="loading-center">
                    <div id="loading-center-absolute">
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="logo-container">
            <img class="logo-img" :src="require('./assets/page-logo.png')"/>
        </div>
        <div class="page-name">
            单个电芯
        </div>
        <div class="search-container">
            <SearchBar />
        </div>
        <div class="battery-cell-container">
            <TitlePanel>
                <BatteryCellInfo />
            </TitlePanel>
        </div>
        <div class="cell-data-list-container">
            <div class="header">
                <div class="header-item"></div>
                <div class="header-item">BMS</div>
                <div class="header-item">云控终端</div>
                <div class="header-item">云端</div>
            </div>
            <div class="content">
                <div class="cell-info-item" v-for="(item, index) in cellDataList" :key="index">
                   <div class="cell-info-item-column"><div class="empty"></div>{{ item.name }}</div>
                   <div class="cell-info-item-column">{{ item.bms }}</div>
                   <div class="cell-info-item-column">{{ item.yunClient }}</div>
                   <div class="cell-info-item-column">{{ item.yun }}</div>
                </div>
            </div>
        </div>
        <div class="cell-elec-line">
            <span class="elec-e">E 3320mV</span>
            <span class="elec-ro">R0 0.2mΩ</span>
            <span class="elec-r1">R1 0.12mΩ</span>
            <span class="elec-c1">C1 0.5uF</span>
            <span class="elec-r2">R2 0.04mΩ</span>
            <span class="elec-c2">C2 0.2mF</span>
            <span class="elec-reduce">负极电位 1070mV</span>
        </div>
        <div class="voltage-current-scatter">
            <VoltageCurrentScatter />
        </div>
        <div class="surface-temperature-scatter">
            <SurfaceTemperatureScatter />
        </div>
        <div class="soc-soh-scatter">
            <SocSohScatter />
        </div>
        <div class="internal-resistance-scatter">
            <InternalResistanceScatter />
        </div>
        <div class="negative-point-line">
            <NegativePoint />
        </div>
        <div class="interna-short-circuit-line">
            <InternaShortCircuitLine />
        </div>
        <div class="self-discharge-line">
            <SelfDischargeLine />
        </div>
        <div class="lithium-line">
            <LithiumLine />
        </div>
        <div class="ocv-line">
            <OCVLine />
        </div>
        <div class="sop-line">
            <SOPLine />
        </div>
        <div class="attenuation-line">
            <AttenuationLine />
        </div> 
    </div>
</template>

<script>
import './Index.less';
import SearchBar from './components/SearchBar.vue';
import TitlePanel from './components/TitlePanel.vue';
import BatteryCellInfo from './components/BatteryCellInfo.vue';
import VoltageCurrentScatter from './components/VoltageCurrentScatter.vue';
import SurfaceTemperatureScatter from './components/SurfaceTemperatureScatter.vue';
import SocSohScatter from './components/SocSohScatter.vue';
import InternalResistanceScatter from './components/InternalResistanceScatter.vue';
import NegativePoint from './components/NegativePoint.vue';
import InternaShortCircuitLine from './components/InternaShortCircuitLine.vue';
import SelfDischargeLine from './components/SelfDischargeLine.vue';
import LithiumLine from './components/LithiumLine.vue';
import OCVLine from './components/OCVLine.vue';
import SOPLine from './components/SOPLine.vue';
import AttenuationLine from './components/AttenuationLine.vue';

export default {
    name: 'DataCenterCell',
    components: {
        SearchBar,
        TitlePanel,
        BatteryCellInfo,
        VoltageCurrentScatter,
        SurfaceTemperatureScatter,
        SocSohScatter,
        InternalResistanceScatter,
        NegativePoint,
        InternaShortCircuitLine,
        SelfDischargeLine,
        LithiumLine,
        OCVLine,
        SOPLine,
        AttenuationLine
    },
    data: () => ({
        pageStyle: {
            transformOrigin: 'left top 0px'
        },
        pageWidth: 1920,
        pageHigth: 1080,
        cellDataList: [
            { name: '电压/mV', bms: '3320', yunClient: '3320', yun: '3320' },
            { name: '电流/A', bms: '0', yunClient: '0', yun: '0' },
            { name: '表面温度/℃', bms: '--', yunClient: '25', yun: '25' },
            { name: '核心温度/℃', bms: '--', yunClient: '27.5', yun: '27.5' },
            { name: 'SOC/%', bms: '--', yunClient: '56', yun: '56' },
            { name: 'SOH/%', bms: '--', yunClient: '99', yun: '99' },
            { name: 'SOE/wh', bms: '--', yunClient: '95', yun: '95' },
            { name: 'SOP/W', bms: '--', yunClient: '189', yun: '189' },
            { name: '累计充电电量/Ah', bms: '--', yunClient: '21900', yun: '21900' },
            { name: '累计放电电量/Ah', bms: '--', yunClient: '21487', yun: '21487' },
            { name: '累计循环时间', bms: '--', yunClient: '355天5h', yun: '355天5h' },
            { name: '累计循环次数/cycle', bms: '--', yunClient: '358.1', yun: '358.1' },
            { name: '累计故障数/次', bms: '--', yunClient: '0', yun: '0' },
            { name: '充电SOC限值/%', bms: '--', yunClient: '--', yun: '97.5' },
            { name: '放电SOC限值/%', bms: '--', yunClient: '--', yun: '5' },
            { name: '自放电率/%/月', bms: '--', yunClient: '--', yun: '1.3' },
            { name: '故障预警', bms: '--', yunClient: '--', yun: '无故障' },
            { name: '寿命预测', bms: '--', yunClient: '--', yun: '1216天' },
            { name: '析锂指数/次/万公里', bms: '--', yunClient: '--', yun: '0.0004' },
            { name: '内短路指数/次/万公里', bms: '--', yunClient: '--', yun: '0.000003' },
        ],
        loading: true
    }),
    methods: {
        pageScale() {
            const { clientWidth, clientHeight } = this.$refs['data-center-cell-container'];
            const { pageWidth, pageHigth } = this;
            const xScale = clientWidth / pageWidth;
            const yScale = clientHeight / pageHigth;
            const pageStyle = {
                ...this.pageStyle,
                width: `${pageWidth}px`,
                height: `${pageHigth}px`,
                transform: `scale(${xScale}, ${yScale})`
            };
            this.pageStyle = pageStyle;
        }
    },
    mounted() {
        this.pageScale();
        document.onreadystatechange = () => {
            if (document.readyState === 'complete') {
                this.loading = false;
            }
        };
    }
}
</script>
