<template>
    <div ref="interna-short-chart-container" class="interna-short-chart-container">
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'InternaShortCircuitLine',
    methods: {
        getOption() {
            const option = {
                legend: {
                    right: 10,
                    top: 10,
                    data: ['内短路'],
                    textStyle: {
                        color: '#01AAF2',
                        fontSize: 16
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    top: 40,
                    bottom: 40,
                    left: 75
                },
                xAxis: {
                    type: 'category',
                    data: [
                        '2021/7/12 0:00',
                        '2021/7/12 1:00',
                        '2021/7/12 2:00',
                        '2021/7/12 3:00',
                        '2021/7/12 4:00',
                        '2021/7/12 5:00',
                        '2021/7/12 6:00',
                        '2021/7/12 7:00',
                        '2021/7/12 8:00',
                        '2021/7/12 9:00',
                        '2021/7/12 10:00',
                        '2021/7/12 11:00',
                        '2021/7/12 12:00',
                        '2021/7/12 13:00',
                        '2021/7/12 14:00',
                        '2021/7/12 15:00',
                        '2021/7/12 16:00',
                        '2021/7/12 17:00',
                        '2021/7/12 18:00',
                        '2021/7/12 19:00',
                        '2021/7/12 20:00',
                        '2021/7/12 21:00',
                        '2021/7/12 22:00',
                        '2021/7/12 23:00'
                    ],
                    axisLabel: {
                        color: '#21abfa',
                        fontSize: 12,
                        fontWeight: 700
                    },  
                    axisLine: {
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    boundaryGap: false,
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#21abfa',
                        fontSize: 12,
                        fontWeight: 700,
                    }, 
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#094B85'],
                            opacity: 0.3
                        }
                    }
                },
                series: [
                    {
                        name: '内短路',
                        data: [0.000003, 0.000003, 0.000003, 0.000003, 0.000003, 0.000003, 0.000003,0.000003, 0.000003, 0.000003,0.000003, 0.000003, 0.000003, 0.000003, 0.000003, 0.000003, 0.000003, 0.000003, 0.000003, 0.000003, 0.000003, 0.000003, 0.000003, 0.000003],
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            color: 'rgba(45, 146, 255, 1)',
                            width: 3
                        }
                    }
                ]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['interna-short-chart-container'];
        const myChart = echarts.init(chartDom);
        myChart.setOption(this.getOption());
    }
}
</script>

<style lang="less">
    .interna-short-chart-container {
        width: 100%;
        height: 100%;
    }
</style>