<template>
  <div ref="surface-temperature-scatter-container" class="surface-temperature-scatter-container">

  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'SurfaceTemperatureScatter',
    methods: {
        getOption() {
            const option = {
                legend: {
                    top: 10,
                    data: [{
                        name: '表面温度',
                        lineStyle: {
                            color: 'rgba(41, 150, 157, 1)'
                        }
                    }],
                    textStyle: {
                        color: '#01AAF2',
                        fontSize: 16
                    },
                    right: 20,
                },
                tooltip: {
                    position: 'top',
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                xAxis: {
                    data: [
                        '2021/7/12 0:00',
                        '2021/7/12 1:00',
                        '2021/7/12 2:00',
                        '2021/7/12 3:00',
                        '2021/7/12 4:00',
                        '2021/7/12 5:00',
                        '2021/7/12 6:00',
                        '2021/7/12 7:00',
                        '2021/7/12 8:00',
                        '2021/7/12 9:00',
                        '2021/7/12 10:00',
                        '2021/7/12 11:00',
                        '2021/7/12 12:00',
                        '2021/7/12 13:00',
                        '2021/7/12 14:00',
                        '2021/7/12 15:00',
                        '2021/7/12 16:00',
                        '2021/7/12 17:00',
                        '2021/7/12 18:00',
                        '2021/7/12 19:00',
                        '2021/7/12 20:00',
                        '2021/7/12 21:00',
                        '2021/7/12 22:00',
                        '2021/7/12 23:00'
                    ],
                    axisLabel: {
                        color: '#E3F0FF',
                        fontSize: 12,
                        fontWeight: 700
                    },  
                    axisLine: {
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#094B85'],
                            opacity: 0.3
                        }
                    }
                },
                yAxis: {
                    axisLabel: {
                        color: '#E3F0FF',
                        fontSize: 12,
                        fontWeight: 700
                    }, 
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#094B85'],
                            opacity: 0.3
                        }
                    }
                },
                grid: {
                    top: 40,
                    bottom: 40,
                    left: 50
                },
                series: [{
                    name: '表面温度',
                    symbolSize: 10,
                    data: [
                        [0, 26],
                        [1, 27],
                        [2, 28],
                        [3, 29],
                        [4, 29],
                        [5, 28],
                        [6, 27],
                        [7, 27],
                        [8, 27],
                        [9, 26],
                        [10, 26],
                        [11, 27],
                        [12, 27],
                        [13, 28],
                        [14, 28],
                        [15, 28],
                        [16, 29],
                        [17, 29],
                        [18, 29],
                        [19, 30],
                        [20, 30],
                        [21, 30],
                        [22, 31],
                        [23, 31],
                    ],
                    type: 'scatter',
                    itemStyle: {
                        color: 'rgba(41, 150, 157, 1)'
                    }
                }]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['surface-temperature-scatter-container'];
        const chart = echarts.init(chartDom);
        this.chart = chart;
        this.chart.setOption(this.getOption());
    }
}
</script>

<style lang="less">
    .surface-temperature-scatter-container {
        width: 100%;
        height: 100%;
    }
</style>