<template>
  <div ref="voltage-current-scatter-container" class="voltage-current-scatter-container">

  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'VoltageCurrentScatter',
    methods: {
        getOption() {
            const option = {
                legend: {
                    top: 10,
                    data: [{
                        name: '电压',
                        lineStyle: {
                            color: 'rgba(142, 91, 188, 1)'
                        }
                    }, {
                        name: '电流',
                        lineStyle: {
                            color: 'rgba(41, 150, 157, 1)'
                        }
                    }],
                    textStyle: {
                        color: '#01AAF2',
                        fontSize: 16
                    },
                    right: 20,
                },
                tooltip: {
                    position: 'top',
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                xAxis: {
                    data: [
                        '2021/7/12 0:00',
                        '2021/7/12 1:00',
                        '2021/7/12 2:00',
                        '2021/7/12 3:00',
                        '2021/7/12 4:00',
                        '2021/7/12 5:00',
                        '2021/7/12 6:00',
                        '2021/7/12 7:00',
                        '2021/7/12 8:00',
                        '2021/7/12 9:00',
                        '2021/7/12 10:00',
                        '2021/7/12 11:00',
                        '2021/7/12 12:00',
                        '2021/7/12 13:00',
                        '2021/7/12 14:00',
                        '2021/7/12 15:00',
                        '2021/7/12 16:00',
                        '2021/7/12 17:00',
                        '2021/7/12 18:00',
                        '2021/7/12 19:00',
                        '2021/7/12 20:00',
                        '2021/7/12 21:00',
                        '2021/7/12 22:00',
                        '2021/7/12 23:00'
                    ],
                    axisLabel: {
                        color: '#E3F0FF',
                        fontSize: 12,
                        fontWeight: 700
                    },  
                    axisLine: {
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#094B85'],
                            opacity: 0.3
                        }
                    }
                },
                yAxis: {
                    axisLabel: {
                        color: '#E3F0FF',
                        fontSize: 12,
                        fontWeight: 700
                    }, 
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#094B85'],
                            opacity: 0.3
                        }
                    }
                },
                grid: {
                    top: 40,
                    bottom: 40,
                    left: 50
                },
                series: [{
                    name: '电压',
                    symbolSize: 10,
                    data: [
                        [0, 3230],
                        [1, 3230],
                        [2, 3230],
                        [3, 3410],
                        [4, 3632.5],
                        [5, 3602.5],
                        [6, 3572.5],
                        [7, 3542.5],
                        [8, 3512.5],
                        [9, 3482.5],
                        [10, 3358],
                        [11, 3326],
                        [12, 3326],
                        [13, 3326],
                        [14, 3326],
                        [15, 3326],
                        [16, 3326],
                        [17, 3326],
                        [18, 3326],
                        [19, 3326],
                        [20, 3326],
                        [21, 3326],
                        [22, 3325.1],
                        [23, 3319.1],
                    ],
                    type: 'scatter',
                    itemStyle: {
                        color: 'rgba(142, 91, 188, 1)'
                    }
                },{
                    name: '电流',
                    symbolSize: 5,
                    itemStyle: {
                        color: 'rgba(41, 150, 157, 1)'
                    },
                    data: [
                        [0, -9.5],
                        [1, -9.5],
                        [2, -9.5],
                        [3, -4.5],
                        [4, 0],
                        [5, 0],
                        [6, 0],
                        [7, 0],
                        [8, 0],
                        [9, 0],
                        [10, 10],
                        [11, 10],
                        [12, 10],
                        [13, 10],
                        [14, 10],
                        [15, 10],
                        [16, 10],
                        [17, 10],
                        [18, 10],
                        [19, 10],
                        [20, 10],
                        [21, 10],
                        [22, 10],
                        [23, 10],
                    ],
                    type: 'scatter'
                }]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['voltage-current-scatter-container'];
        const chart = echarts.init(chartDom);
        this.chart = chart;
        this.chart.setOption(this.getOption());
    }
}
</script>

<style lang="less">
    .voltage-current-scatter-container {
        width: 100%;
        height: 100%;
    }
</style>