<template>
    <div class="search-bar-container">
        <el-input class="search-input" placeholder="请输入电芯SN号" v-model="searchValue"/>
        <img class="search-icon" :src="require('../assets/search.png')"/>
        <div class="slider-bar-container">
            <div class="pre-button-icon">
                <div class="left-arrow"></div>
            </div>
            <div class="pre-button">
                上一串
            </div>
            <div class="page-count">12</div>
            <div class="next-button">
                下一串
            </div>
            <div class="next-button-icon">
                <div class="right-arrow"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SearchBar',
    data: () => ({
        searchValue: ''
    })
}
</script>

<style lang="less">
    .search-bar-container {
        display: flex;
        position: relative;
        height: 36px;
        .search-input {
            width: 300px;
            height: 36px;
            .el-input__inner {
                height: 36px;
                background: rgba(63, 227, 237, .1);
                border-radius: 4px;
                color: #fff;
                border: none;
            }
        }
        .search-icon {
            position: absolute;
            top: 10px;
            left: 280px;
        }
        .slider-bar-container {
            box-sizing: border-box;
            height: 36px;
            width: calc(100% - 300px);
            display: flex;
            align-items: center;
            padding: 0 10px;
            .pre-button-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                width: 30px;
                height: 30px;
                margin-right: 12px;
                border: 1px solid #2F517E;
                cursor: pointer;
                .left-arrow {
                    width: 6px;
                    height: 6px;
                    border-top: 1px solid rgba(227, 240, 255, 1);
                    border-left: 1px solid rgba(227, 240, 255, 1);
                    transform: rotate(-45deg)
                }
            }
            .pre-button, .next-button {
                box-sizing: border-box;
                width: 70px;
                height: 30px;
                border: 1px solid #2F517E;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #E3F0FF;
                line-height: 30px;
                text-align: center;
                cursor: pointer;
            }
            .page-count {
                box-sizing: border-box;
                width: 50px;
                height: 30px;
                margin: 0 12px;
                border: 1px solid #2F517E;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #E3F0FF;
                line-height: 30px;
                text-align: center;
                cursor: pointer;
            }
            .next-button-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                width: 30px;
                height: 30px;
                margin-left: 12px;
                border: 1px solid #2F517E;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                .right-arrow {
                    width: 6px;
                    height: 6px;
                    border-top: 1px solid rgba(227, 240, 255, 1);
                    border-left: 1px solid rgba(227, 240, 255, 1);
                    transform: rotate(135deg)
                }
            }
        }
    }
</style>