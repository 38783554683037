<template>
  <div ref="soc-soh-scatter-container" class="soc-soh-scatter-container">

  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'SocSohScatter',
    methods: {
        getOption() {
            const option = {
                legend: {
                    top: 10,
                    data: [{
                        name: 'SOC',
                        lineStyle: {
                            color: 'rgba(113, 104, 97, 1)'
                        }
                    }, {
                        name: 'SOH',
                        lineStyle: {
                            color: 'rgba(125, 85, 137, 1)'
                        }
                    }],
                    textStyle: {
                        color: '#01AAF2',
                        fontSize: 16
                    },
                    right: 20,
                },
                tooltip: {
                    position: 'top',
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                xAxis: {
                    data: [
                        '2021/7/12 0:00',
                        '2021/7/12 1:00',
                        '2021/7/12 2:00',
                        '2021/7/12 3:00',
                        '2021/7/12 4:00',
                        '2021/7/12 5:00',
                        '2021/7/12 6:00',
                        '2021/7/12 7:00',
                        '2021/7/12 8:00',
                        '2021/7/12 9:00',
                        '2021/7/12 10:00',
                        '2021/7/12 11:00',
                        '2021/7/12 12:00',
                        '2021/7/12 13:00',
                        '2021/7/12 14:00',
                        '2021/7/12 15:00',
                        '2021/7/12 16:00',
                        '2021/7/12 17:00',
                        '2021/7/12 18:00',
                        '2021/7/12 19:00',
                        '2021/7/12 20:00',
                        '2021/7/12 21:00',
                        '2021/7/12 22:00',
                        '2021/7/12 23:00'
                    ],
                    axisLabel: {
                        color: '#E3F0FF',
                        fontSize: 12,
                        fontWeight: 700
                    },  
                    axisLine: {
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#094B85'],
                            opacity: 0.3
                        }
                    }
                },
                yAxis: {
                    axisLabel: {
                        color: '#E3F0FF',
                        fontSize: 12,
                        fontWeight: 700
                    }, 
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#094B85'],
                            opacity: 0.3
                        }
                    }
                },
                grid: {
                    top: 40,
                    bottom: 40,
                    left: 50
                },
                series: [{
                    name: 'SOC',
                    symbolSize: 10,
                    data: [
                        [0, 38.5],
                        [1, 56.5],
                        [2, 74.5],
                        [3, 92.5],
                        [4, 100],
                        [5, 100],
                        [6, 100],
                        [7, 100],
                        [8, 100],
                        [9, 100],
                        [10, 99.84],
                        [11, 95.04],
                        [12, 88.94],
                        [13, 78.14],
                        [14, 71.57],
                        [15, 70.76],
                        [16, 71.3],
                        [17, 69.32],
                        [18, 63.92],
                        [19, 58.52],
                        [20, 46.85],
                        [21, 34.85],
                        [22, 22.85],
                        [23, 18.05],
                    ],
                    type: 'scatter',
                    itemStyle: {
                        color: 'rgba(113, 104, 97, 1)'
                    }
                },{
                    name: 'SOH',
                    symbolSize: 5,
                    itemStyle: {
                        color: 'rgba(125, 85, 137, 1)'
                    },
                    data: [
                        [0, 99],
                        [1, 99],
                        [2, 99],
                        [3, 99],
                        [4, 99],
                        [5, 99],
                        [6, 99],
                        [7, 99],
                        [8, 99],
                        [9, 99],
                        [10, 99],
                        [11, 99],
                        [12, 99],
                        [13, 99],
                        [14, 99],
                        [15, 99],
                        [16, 99],
                        [17, 99],
                        [18, 99],
                        [19, 99],
                        [20, 99],
                        [21, 99],
                        [22, 99],
                        [23, 99],
                    ],
                    type: 'scatter'
                }]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['soc-soh-scatter-container'];
        const chart = echarts.init(chartDom);
        this.chart = chart;
        this.chart.setOption(this.getOption());
    }
}
</script>

<style lang="less">
    .soc-soh-scatter-container {
        width: 100%;
        height: 100%;
    }
</style>