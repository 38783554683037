<template>
    <div class="title-panel-container">
        <div class="title">
            <div class="title-icon">
                <div class="line-icon"></div>
                <div class="line-icon"></div>
                <div class="line-icon"></div>
            </div>
            <span class="title-name">
                {{ title }}
            </span>
        </div>
        <div class="content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TitlePanel',
    props: {
        title: {
            type: String,
            default: '电芯'
        }
    }
}
</script>


<style lang="less" scoped>
    .title-panel-container {
        width: 100%;
        height: 100%;
        .title {
            display: flex;
            align-items: center;
            height: 50px;
            .title-icon {
                display: flex;
                height: 14px;
                margin: 0 12px;
                .line-icon {
                    width: 4px;
                    height: 14px;
                    margin: 0 2px;
                    background-color: rgba(63, 227, 237, 1);
                }
            }
            .title-name {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #3FE3ED;
            }
        }
        .content {
            height: calc(100% - 50px);
        }
    }
</style>